@import url("https://fonts.googleapis.com/css?family=Shadows+Into+Light|Quicksand|Playfair+Display:700i,900");
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;500&display=swap');

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1 {
  font-size: 34px;
  text-align: left;
  margin-bottom: 8px;
}

h2 {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  /* margin: 40px 0 40px 0px; */
  text-align: left;
  line-height: 34.5px;
}

h2 .soulman{
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  margin: 40px 0 40px 0px;
  text-align: left;
  line-height: 34.5px;
}

p,
i,
a,
li,
span {
  font-family: "Inter, sans-serif", sans-serif;
  color: #000000 !important;
  letter-spacing: -0.03px;
}

a {
  text-decoration: none;
}

.img-border {
  border-radius: 8px;
}

.bg {
    background-color: #156BFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%232166ff' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%232d63ff' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%233860ff' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%234460ff' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%235060FF' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%235b45ff' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%237b3bff' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%239f30ff' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23c826ff' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23F51BFF' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
}

.bg-projects {
  background-color: #3a46dd;
  opacity: 1;
  background-image:  radial-gradient(#686ff1 2px, transparent 2px), radial-gradient(#686ff1 2px, #3a46dd 2px);
  background-size: 80px 80px;
  background-position: 0 0,40px 40px;
  background-attachment: fixed;

}

.btn-outline-orange {
  color: #fc8822 !important;
  border-color: #fc8822 !important;
}

.btn-outline-orange:hover,
.btn-outline-orange:active,
.btn-outline-orange:focus,
.btn-outline-orange.active {
  background: #fc8822;
  color: #ffffff !important;
  border-color: #924d10 !important;
}

.ethereum-icon {
  filter: invert(91%) sepia(1%) saturate(0%) hue-rotate(154deg) brightness(88%) contrast(92%);
  width: 40px;
  height: 40px;
}

.listIcon {
  filter: invert(22%) sepia(62%) saturate(3749%) hue-rotate(232deg) brightness(90%) contrast(86%);
  width: 15px;
  height: 15px;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.0rem !important;
    padding-left: 1.0rem !important;
}

.navbar-nav a:hover {
  text-decoration: none !important;
}

@media only screen and (min-width: 320px) {
  .soulman-logo {
    font-size: 20px;
    font-weight: 700;
    margin-top: 7px;
  }

  .ethereum {
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
  }

  .headerIcon {
    filter: invert(100%) sepia(64%) saturate(173%) hue-rotate(201deg) brightness(125%) contrast(100%);
    width: 200px;
    height: 200px;
  }
}

@media only screen and (min-width: 576px) {
  .soulman-logo {
    font-size: 26px;
    font-weight: 700;
    margin-top: 3px;
  }

  .ethereum {
    font-size: 18px;
    font-weight: 700;
    margin-top: 16px;
  }
  
  .headerIcon {
    filter: invert(100%) sepia(64%) saturate(173%) hue-rotate(201deg) brightness(125%) contrast(100%);
    width: 250px;
    height: 250px;
  }
}



.nav {
    display: flex;
    flex-basis: auto;

}

.nav-list {
    flex-direction: row;
    display: flex;
    flex-basis: auto;
    list-style: none;
    margin-top: 0;
}


blockquote {
  font-family: "Playfair Display", serif;
  font-size: 25px;
  font-style: italic;
  letter-spacing: -0.36px;
  line-height: 44.4px;
  overflow-wrap: break-word;
  margin: 30px 0 33px 0;
  padding: 0 0 0 1em;
  border-left: .25em solid #dfe2e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 3px 5px;
}

pre > code {
  background: transparent !important;
  font-family: "Quicksand";
  font-size: 18px;
  padding: 0px;
}

pre {
  margin-top: 30px;
  border-radius: 10px;
  padding: 20px !important;
}

mark,
.highlighted {
  background: #7dffb3;
}

.first-letter {
  overflow-wrap: break-word;
  font-family: "Playfair Display", serif;
  font-size: 60px;
  line-height: 60px;
  display: block;
  position: relative;
  float: left;
  margin: 0px 7px 0 -5px;
}

.subtitle {
  font-family: "Inter,sans-serif", sans-serif;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 24px 0;
}

::selection {
  background-color: lavender;
}

/* Slide up */

.blog-post-anchor {
  color: inherit;
  text-decoration: none;
  box-shadow: inset 0 -0.125em 0 #434C5E;
  transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
  padding-right: 2px;
  padding-left: 2px;
}

.blog-post-anchor:hover {
  box-shadow: inset 0 -1.125em 0 #434C5E;
  color: white;
}

img {
  max-width: 100%;
  height: auto;
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media only screen and (max-width: 768px) {
  p,
  i,
  a,
  li {
    font-size: 18px;
  }
  blockquote {
    font-size: 20px;
  }
}



.card {
    border: 0 !important;
    border-radius: 5px;
    margin-bottom: 30px;
}

.card .card-header {
    background-color: transparent;
    border-bottom: none;
}

.card .card-block ul, li {
  font-size: 14px;
  list-style: none;
  vertical-align: middle;
}

.card .card-header h5 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    display: inline-block;
    line-height: 1.4;
    margin-bottom: 0;
}

.user-card .card-block {
    text-align: left;
}

.card .card-block {
    padding: 25px;
}

.user-card .card-block .user-image {
    position: relative;
    margin: 0 auto;
    display: inline-block;
    padding: 5px;
    width: 110px;
    height: 110px;
}

.user-card .card-block .user-image img {
    z-index: 20;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100px;
    height: 100px;
}

.img-radius {
    border-radius: 50%;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-15 {
    margin-top: 15px;
}

.card .card-block p {
    line-height: 1.4;
}

.text-muted {
    color: #919aa3!important;
}

.user-card .card-block .activity-leval li.active {
    background-color: #000000;
}

.user-card .card-block .activity-leval li {
    display: inline-block;
    width: 15%;
    height: 4px;
    margin: 0 3px;
    background-color: #000000;
}

.user-card .card-block .counter-block {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#2967ae,#4c8fdc);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}

.m-t-10 {
    margin-top: 10px;
}

.p-20 {
    padding: 20px;
}

.user-card .card-block .user-social-link i {
    font-size: 30px;
}

.text-facebook {
    color: #3B5997;
}

.text-twitter {
    color: #42C0FB;
}

.text-dribbble {
    color: #EC4A89;
}

.user-card .card-block .user-image:before {
    bottom: 0;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.user-card .card-block .user-image:after, .user-card .card-block .user-image:before {
    content: "";
    width: 100%;
    height: 48%;
    border: 2px solid #4099ff;
    position: absolute;
    left: 0;
    z-index: 10;
}

.user-card .card-block .user-image:after {
    top: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.user-card .card-block .user-image:after, .user-card .card-block .user-image:before {
    content: "";
    width: 100%;
    height: 48%;
    border: 2px solid #4099ff;
    position: absolute;
    left: 0;
    z-index: 10;
}

.mg-t-minus25 {
  margin-top: -25em;
}

.mg-t-minus10 {
  margin-top: -7em;
}

.image-parent {
  margin-right: 5px;
}

.list-group-item {
  border: 0 !important;
}

.flex-column p {
  margin-bottom: 0 !important;
}

.image-parent {
  display: flex;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    font-size: 24px;
    text-align: inherit;
}

.list-group-item-action p {
    width: 100%;
    color: #495057;
    font-size: 16px;
    text-align: inherit;
}

.badge {
    font-size: 50% !important;
}

.form-control {
  background-color: #f0e5e5 !important;
}

.contact-card  {
  padding: 30px;
  margin-left: 150px;
  margin-right: 150px;
}

.form-group label {
    font-weight: 700;
    color: #555555;
}




/* .pattern.bottom {
    bottom: -20px;
    transform: rotate(180deg);
}

.pattern {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: url(../src/Components/Header/wave.svg);
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 1450px 160px;
    height: 0;
    padding: 0;
    padding-bottom: 140px;
} */

.valign {
  margin: auto;
}

.skill-icon {
  padding: 10px;
}

.skill-icon:hover {
  background-color: #dddddd;
}

/* ETHEREUM 2.0 SECTION */

.tx-11 {
  font-size: 11px;
}

.tx-12 {
  font-size: 12px;
}

.tx-blue {
  color: #094bbe;
}

.tx-gray {
  color: #797d86;
}

.tx-white {
  color: #ffffff;
}

.justifycontent {
  justify-content: center;
}

.bg-blue-100 {
  background-color: #5651e6 !important;
}

.bg-orange-100 {
  background-color: #cd822d !important;
}

.bg-gray-100 {
  background-color: #949494 !important;
}

.pt-60 {
  padding-top: 60px;
}